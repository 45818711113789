import * as React from "react"
import { Helmet } from "react-helmet"

import Hero from "../../components/hero"
import PageHeader from "../../components/page-header"
import Card from "../../components/card"
import Footer from "../../components/footer"

import "../../scss/site.scss"
//import StickyCta from "../../components/sticky-cta"

const HistoricalPerspectives = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Historiska Perspektiv | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv"
        ></meta>
        <meta property="og:title" content="Historiska Perspektiv"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--historiska-perspektiv"
        >
          <Hero title="Historiska perspektiv">
            <p>
              Sverige har inte haft riktig demokrati så länge. Kvinnlig rösträtt
              infördes 1921 – för hundra år sedan – och många menar att det var
              då vi blev en fullvärdig demokrati.
            </p>
            <p>
              Men stämmer det verkligen? Vad har förändrats i den svenska
              demokratin under dessa hundra år?
            </p>
          </Hero>
          <section className="c-card-grid">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="tidslinjen-oversikt"
                    title="Tidslinjen"
                    text="Demokratin utvecklas och förändras hela tiden. Tidslinjen visar exempel på beslut och händelser som påverkat demokratin i Sverige."
                    datetime="PT40M"
                    estimate="Övning, ca 40 min"
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <Card
                    to="utan-demokratiska-rattigheter"
                    title="Utan demokratiska rättigheter"
                    text="Varken de allra fattigaste eller de som satt i fängelse hade rösträtt i den tidiga svenska demokratin. Människor som staten ansåg var sämre än andra låstes in på institutioner. Vissa steriliserades så att de aldrig skulle kunna få barn. Minoriteter som samer, romer och judar kunde hånas och förföljas öppet."
                    datetime="PT40M"
                    estimate="Övning, ca 40-120 min"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default HistoricalPerspectives
